// Overrides Account Settings Styling
#settings {
  .social-login-section {
    border: solid 1px $color-cta-grey;
    margin-top: 20px;
    padding: 20px;
    .profile-page {
      .social-info {
        &__connect {
          border-bottom: 1px solid $color-cta-grey;
          padding-bottom: 10px;
        }
      }
    }
    .settings-info {
      &__header {
        border: none;
        font-size: 22px;
        @include breakpoint($bp--medium-up) {
          font-size: 30px;
        }
      }
      .footer-social-links {
        display: inline-block;
        border-bottom: solid 1px $color-cta-grey;
      }
      .social {
        &-connect,
        &-disconnect {
          cursor: pointer;
          float: $rdirection;
          font-size: 15px;
          margin-top: 20px;
          a {
            text-decoration: none;
          }
        }
      }
    }
    .social-text {
      font-size: 12px;
      @include breakpoint($bp--medium-up) {
        font-size: 15px;
      }
    }
  }
}

.disconnect-confirm-overlay {
  .disconnect {
    &-no,
    &-yes {
      float: $ldirection;
      padding: 12px;
      margin-top: 10px;
      background-color: $color--black;
      color: $color--white;
      margin-#{$ldirection}: 10px;
      cursor: pointer;
      a {
        color: $color--white;
        text-decoration: none;
        border: 0;
      }
    }
    &-overlay {
      padding-top: 20px;
      &-header {
        b {
          font-size: 1.4rem;
          font-weight: 700;
        }
      }
    }
  }
}
