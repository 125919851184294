.checkout {
  &__new-account,
  &__return-user {
    .form-submit {
      width: 100%;
    }
  }
  &__content {
    padding: 0 1em;
    .signin-panel {
      border-bottom: 0;
      #forgot-pass {
        padding: 5px 0px 0px;
      }
      #content_breadcrumb {
        ol.checkout-progress {
          li {
            display: inline;
            margin-right: 40px;
            font-size: 13px;
          }
        }
      }
    }
    .gift-message {
      #message {
        textarea {
          resize: none;
        }
      }
      #to,
      #from,
      #message {
        width: 218px;
        margin-bottom: 20px;
      }
    }
    #address_form_container,
    .address_form_container {
      .form-item {
        width: 50%;
        padding-right: 1.3333em;
        float: left;
        padding-bottom: 1em;
        margin-bottom: 0;
      }
    }
    .cart-item {
      &__qty.samples {
        text-align: center;
      }
    }
    .recommended-product-items {
      .info {
        height: 14em;
      }
    }
    .product_subline {
      text-transform: uppercase;
      font-size: 1rem;
    }
  }
  &.viewcart {
    .checkout-header {
      &__title {
        width: 100%;
        padding: 1em 0;
      }
      &__samples {
        text-align: center;
        margin-bottom: 20px;
      }
      #top-viewcart-buttons {
        width: 100%;
        .continue-buttons {
          width: 75%;
          padding-right: calc(10% - 1.8em);
        }
        .continue-buttons {
          @include breakpoint($bp--medium) {
            width: 97%;
          }
        }
      }
    }
    #bottom-viewcart-buttons {
      .continue-buttons {
        padding-right: 10%;
      }
    }
    .error_messages {
      padding-bottom: 20px;
      padding-top: 30px;
    }
    .checkout__content {
      .signin_see_cart {
        margin-bottom: 0;
      }
      .cart-header {
        &__title--total {
          width: 13%;
        }
      }
    }
    .order-summary {
      &-panel {
        border-top: 1px solid $color--gray--lighter;
        border-bottom: none;
      }
      &__total {
        border-top: 1px solid $color--gray--lighter;
        width: 66.66%;
        padding-top: 20px;
      }
      &__value {
        border-top: 1px solid $color--gray--lighter;
        width: 16.66%;
        padding-top: 20px;
      }
    }
    .action-link {
      &.mobile_hidden {
        @include breakpoint($bp--medium) {
          display: inline-block !important;
        }
      }
    }
  }
  .checkout__return-user {
    #new-account-h {
      margin: 4px 0 5px 0;
      .checkout__subtitle {
        display: inline;
      }
      p {
        margin: 0;
        display: inline;
        float: right;
      }
    }
  }
  .checkout__return-user__toggle {
    margin: 8px 0 15px 270px;
    float: left;
  }
  .samples-panel {
    .samples-panel__title {
      &.checkout-header__title {
        padding-left: 0;
        width: 100%;
      }
    }
  }
}

.site-container {
  .checkout__sidebar {
    .past-purchases-panel {
      a {
        color: $color--black;
      }
    }
    a {
      color: $color--white;
    }
    .offer-code-panel {
      .form-item {
        text-align: center;
        .offer_submit {
          width: 172px;
        }
      }
    }
    .checkout__panel--heading {
      font-size: 24px;
    }
  }
  .panel {
    input {
      border-color: $color--gray--lighter;
    }
  }
  #content_breadcrumb {
    margin-right: 40px;
    width: 640px;
    padding: 0 20px;
    .checkout-progress {
      margin-top: 25px;
      margin-bottom: 25px;
      li {
        display: inline;
        margin-right: 40px;
        width: 100%;
        font-size: 13px;
      }
    }
  }
  .review {
    .checkout-header {
      .checkout-header__title {
        width: 100%;
        padding: 15px 0;
      }
    }
    .checkout__content {
      padding: 0 1em;
      .sign-in-panel {
        border-bottom: 0;
      }
      #continue-checkout-btm {
        .continue-button-wrapper {
          .form-item {
            margin-top: 15px;
            .checkout-buttons {
              width: 228px;
              float: right;
            }
          }
        }
      }
      .cart-item-rows {
        position: relative;
        border-bottom: 1px solid $color--gray--lighter;
      }
      .cart-items__header {
        display: block;
        padding: 0 1em;
        .cart-items__header--thumb,
        .cart-items__header--products,
        .cart-items__header--price,
        .cart-items__header--qty,
        .cart-items__header--total {
          font-size: 15px;
          float: left;
          padding: 1em 1em 1em 0;
        }
        .cart-items__header--thumb {
          width: 15%;
        }
        .cart-items__header--products {
          width: 35%;
        }
        .cart-items__header--price {
          width: 18%;
        }
        .cart-items__header--qty {
          width: 18%;
        }
        .cart-items__header--total {
          width: 13%;
        }
      }
      #review-address-info {
        .trans_detail_item {
          float: left;
          margin-bottom: 25px;
          margin-right: 25px;
          position: relative;
          width: 46%;
          word-wrap: break-word;
        }
        .change_link {
          position: absolute;
          right: 0;
          top: 12px;
        }
      }
      .checkout__panel {
        .continue-button-wrapper {
          .form-item {
            margin-top: 15px;
            .checkout-buttons {
              width: 228px;
              float: right;
            }
          }
        }
      }
    }
  }
  .checkout-confirmation-page {
    .checkout {
      &__content {
        padding: 0 1em;
        div {
          padding: 10px 0px;
        }
        .picker-checkbox {
          .picker-handle,
          .picker-flag {
            padding: 0;
          }
        }
        .print-buttons {
          padding: 10px 0;
          background: $color--black;
          margin: 10px 0px;
          width: 228px;
          text-decoration: none;
          text-align: center;
          a {
            line-height: 40px;
            height: 40px;
            color: $color--white;
            border-bottom: none;
          }
        }
      }
      &-buttons__container {
        padding: 10px 0;
        margin: 10px 0px 10px 0px;
        width: 228px;
        text-decoration: none;
        text-align: center;
        background: $color--black;
        a {
          line-height: 40px;
          height: 40px;
          color: $color--white;
          border-bottom: none;
        }
      }
    }
    #links-panel {
      background: $color--black;
      color: $color--white;
      padding: 1em;
    }
  }
  .non-installment-options,
  .installment-options,
  .linepay-options {
    border: 1px solid $color--gray--lightest;
    margin-bottom: 10px;
    width: 60%;
    .header-section {
      display: inline-block;
      width: 100%;
      .non-installment-options-header,
      .installment-options-header {
        padding: 10px;
        float: left;
        width: 75%;
      }
      .nccc-media {
        float: right;
        padding: 5px 5px 0 0;
        img {
          height: 25px;
          width: 35px;
        }
      }
    }
    .linepay-options-header {
      padding: 10px;
    }
    .non-installment-options-radios,
    .installment-options-radios,
    .linepay-options-radios {
      padding-left: 10px;
    }
  }
}

#checkout_shipping {
  width: 96%;
  h2,
  h3 {
    font-size: 30px;
  }
  .picker {
    &.picker-radio {
      margin: 15px 0px;
      .picker-label {
        font-size: 18px;
      }
    }
  }
  .continue-checkout {
    margin-bottom: 15px;
    float: right;
  }
  #delivery-options {
    width: 40%;
  }
  .deliver-or-pickup {
    display: inline-block;
  }
  #pickup-options {
    .btn {
      background: none;
      padding: 0;
      border-style: none;
      text-decoration: none;
      a {
        border-bottom: none;
        &:hover {
          border-bottom: 1px solid $color--gray--lighter;
        }
      }
    }
    .pickup-store {
      display: inline-block;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  .invoice-address {
    .picker-checkbox {
      .picker-flag {
        line-height: 15px;
      }
    }
  }
  label {
    display: inline-block;
    font-size: 18px;
  }
  input,
  .select2-container {
    border: 1px solid $color--gray--lighter;
  }
  .select2-container {
    border-bottom: none;
  }
  .required_mark {
    font-size: 18px;
  }
  .viewcart-buttons-panel {
    &.panel {
      border-bottom: none;
    }
    .continue-buttons {
      text-align: left;
    }
  }
  .address-container {
    &.billing-address {
      font-style: normal;
    }
  }
  .gift-message {
    input {
      &.error {
        border: 1px solid $color--red;
      }
      &:focus {
        border: 1px solid $color--black;
      }
    }
  }
}

#payPage {
  .EXPIREDATE_BODY {
    select {
      width: 33%;
      height: 40px;
      line-height: 40px;
    }
  }
  form {
    tbody {
      line-height: 45px;
      .PAN_BODY {
        .PAN_INPUT {
          width: 200px;
        }
      }
      .EXTENNO_BODY {
        .EXTENNO_INPUT {
          width: 80px;
        }
      }
      input[type='text'] {
        padding: 10px;
      }
      .BUTTON_BODY {
        .RESET_BUTTON {
          @include swap_direction(padding, 0 20px);
          color: $white;
          font-family: $ano-bold_regular-font;
          font-size: 15px;
          height: 60px;
          line-height: 64px;
          border-style: solid;
          border-color: $white;
          border-width: 0;
          background-color: $black;
          text-transform: uppercase;
        }
      }
    }
  }
}
#pickup-address {
  .btn {
    a {
      background: $black;
      color: $white;
      padding: 18px 30px;
    }
  }
}
.return-link {
  padding: 25px 0;
  font-size: 15px;
}
