.site-my-mac {
  .gnav {
    .social-login {
      &__email-opt-in {
        label {
          display: contents;
        }
      }
      &__terms {
        padding: 0;
      }
      float: none;
    }
  }
}
/* Disconnect button in profile preference page */
#facebook_disconnect input.form-submit {
  margin: 0;
  float: none;
}
/* Checkout Page Styles */
.checkout__index-content {
  .social-login-section {
    .social-login__terms p {
      margin: 0;
    }
  }
}

.checkout {
  .social-login {
    &__divider {
      width: 92%;
    }
  }
}
/* For displaying the FB Button */
.social-login-section {
  .social-login {
    margin: 0;
    .facebook-button {
      display: none;
    }
    .fb-login-button {
      display: block;
    }
    &__container {
      display: inline;
      position: relative;
    }
    &__email-opt-in {
      text-align: #{$ldirection};
      padding-bottom: 5px;
      .picker {
        margin-bottom: 0;
      }
    }
    &__terms {
      p {
        text-align: justify;
      }
      padding: 0;
    }
    &__divider {
      margin: 0;
    }
  }
}
/* Order Confirmation Page */
#confirmation-panel {
  .social-login-section {
    .social-login {
      margin: 0;
      &__divider {
        display: none;
      }
      &__title,
      &__info {
        display: block;
        text-align: #{$ldirection};
      }
      &__container {
        margin: 0;
      }
      &__terms {
        display: none;
      }
    }
  }
}
/* GNAV Style */
.site-header__utility {
  .gnav-util__content--account {
    .login {
      .social-login {
        &__container {
          position: unset;
        }
        &__email-opt-in {
          text-align: #{$ldirection};
        }
        .facebook-button.facebook-button-disabled {
          display: none;
        }
        &__terms {
          text-align: justify;
          label {
            display: none;
          }
        }
      }
    }
  }
}
/* GNAV Registration */
.gnav-util--account {
  .registration {
    .social-login {
      &__container {
        position: unset;
      }
      &__email-opt-in {
        text-align: #{$ldirection};
      }
      &__terms {
        text-align: justify;
      }
    }
  }
}

.divider {
  .social-login__divider {
    position: static;
    &::before {
      #{$ldirection}: 104%;
    }
    .inner {
      #{$ldirection}: 99.7%;
      top: 30%;
      @include breakpoint($bp--xxlarge-up) {
        #{$ldirection}: 100.7%;
      }
    }
  }
}

@include breakpoint($bp--medium-up) {
  .sign-in-container {
    width: 53%;
    @include breakpoint($bp--xxlarge-up) {
      width: 50%;
    }
  }
}

.site-container {
  .sign-in-page {
    .sign-in-container {
      .social-login {
        &-section {
          max-width: 500px;
          .social-terms__container {
            .social-login__terms {
              margin-#{$ldirection}: 0;
            }
          }
        }
        .social-login__email-opt-in {
          margin-#{$ldirection}: 0;
          .picker-checkbox {
            display: flex;
          }
        }
      }
    }
  }
}
