@if $password_strengthen {
  .password-reset-page {
    .password-reset {
      .password-field__info-reset {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -o-transform: translateY(0);
        @include breakpoint($bp--large-up) {
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -o-transform: translateY(-50%);
        }
      }
    }
  }
  .checkout-registration {
    .password-field {
      &__info-checkout {
        #{$ldirection}: 100%;
        margin-#{$ldirection}: 10px;
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color--gray--dark;
      }
    }
    .form-submit {
      &:disabled,
      &.disabled {
        border: 1px solid $color--gray;
      }
    }
  }
  .new_user__panel {
    .checkout_registration {
      .password-field__info-reset {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -o-transform: translateY(0);
        @include breakpoint($bp--large-up) {
          top: 50%;
          #{$ldirection}: 100%;
          margin-#{$ldirection}: 10px;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -o-transform: translateY(-50%);
        }
      }
    }
  }
  #my-appts-registration-form {
    .registration-password-container {
      .password-field__info {
        #{$ldirection}: 82%;
        margin-#{$ldirection}: 5px;
        width: 170px;
        bottom: 32%;
      }
    }
  }
  #appointment-book-sections {
    .password-field__info {
      #{$ldirection}: 82%;
      margin-#{$ldirection}: 5px;
      width: 170px;
      bottom: 47%;
    }
  }
  .password-field {
    &__info {
      @include breakpoint($bp--medium-up) {
        width: 170px;
      }
    }
  }
}
