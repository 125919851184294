$ab-small-down: max-width $width-large - 2;

.appt-book {
  .confirm {
    .confirm-container {
      #booking-step3 {
        .book-appt-container {
          &.no-last-name {
            .appt-book-first-name {
              width: 100%;
            }
            .appt-book-last-name,
            .register-password-container {
              display: none;
            }
          }
        }
      }
    }
  }
  &.services .book-now-bar {
    &.bottom .button {
      .book-now-bar {
        &__details {
          margin-right: 20px;
        }
      }
    }
  }
}

@include breakpoint($ab-small-down) {
  .appointment-nav {
    .submenu__header {
      .appointment-header-nav__link {
        background-image: none !important;
        text-indent: 0 !important;
        font-weight: bold;
      }
    }
  }
  .appt-book {
    .service-select {
      .service {
        .selection-bar .selection-data {
          font-size: 22px;
          .selection-time {
            padding-#{$ldirection}: 0;
          }
        }
      }
    }
    .appt-book-page-header {
      .appt-book-page-header-content {
        .has_hero_banner {
          padding-top: 18px !important;
        }
      }
    }
  }
}

#account-my-appointments {
  .appointments-container {
    .appointments-buttons {
      .virtual-change-button {
        display: none;
      }
    }
  }
}
