.contact-us-page {
  #contact_us {
    .user-data {
      .form-item {
        .comments {
          height: calc(9.5em - -2px);
          resize: none;
        }
      }
    }
  }
  .contact-nav {
    margin: 0 auto;
    width: 50%;
    li {
      &:nth-of-type(4n + 1) {
        width: 50%;
      }
      &:nth-of-type(4n + 2) {
        margin-right: 0;
        width: 50%;
        float: right;
      }
      .details {
        min-height: 130px;
      }
    }
  }
  #email_an_artist {
    .form-item {
      textarea {
        resize: none;
      }
    }
  }
}

.contact-us-thanks-page {
  margin-top: 30px;
}

.tiny-waitlist-overlay {
  .waitlist_header,
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
}

.page--spp__product {
  .product__share-wrapper {
    .product__social-links {
      li {
        float: right;
      }
    }
  }
  .product__inventory-status {
    .notify-status {
      text-align: center;
      a {
        &.notify_me {
          position: inherit;
          width: 100%;
          padding: 15px 0;
        }
      }
    }
  }
}

.carousel-slide {
  .product--teaser {
    .product__add-to-faves {
      display: none;
    }
  }
}

.search-results {
  .tout-block-landscape__caption {
    left: 0;
  }
}

.site-container {
  .product_name {
    font-family: $roboto-mono_regular-font;
    line-height: 18px;
  }
  .social-login {
    &__divider {
      margin-top: 0;
      span {
        background: black;
        margin-top: 0;
        color: white;
        padding: 4px 5px;
        border-radius: 25px;
        border: 10px solid;
        background-clip: padding-box;
        letter-spacing: 0.02px;
      }
    }
    &__terms {
      margin-bottom: 15px;
      p {
        text-align: center;
      }
    }
    &__container {
      p {
        margin: 0;
      }
    }
    .picker {
      display: inline-block;
    }
  }
  .sign-in-page {
    .sign-in-container {
      width: 100%;
      .signin-form {
        width: 50%;
        float: left;
        .field-container--grid {
          .form-item {
            float: none;
            margin-right: 0;
            padding-right: 0;
            clear: both;
          }
        }
      }
      .social-login {
        &-section {
          margin-top: 0;
        }
        &__email-opt-in {
          margin-left: 65px;
        }
      }
    }
  }
  .product_subline,
  .product-subline,
  .product_name {
    text-transform: uppercase;
    font-size: 1rem;
  }
}

.site-bag__contents-products {
  .cart-product-name {
    .product__subline,
    .product_rgn_name_below_subline {
      padding: 0;
    }
  }
}

.site-header {
  .site-header__tools {
    .site-bag__contents {
      .cart-product-shade {
        padding: 6px 0 10px 0;
      }
      .cart-product-quantity {
        padding: 5px 0 10px 0;
      }
    }
  }
}

.ecommerce {
  &.section-offer-details {
    .site-container {
      overflow-y: hidden;
    }
  }
}

.device-pc {
  .homepage-formatter {
    .carousel--has-dots {
      .slick-dots {
        bottom: 10px;
      }
    }
  }
}

nav.breadcrumbs {
  background-color: transparent;
}
