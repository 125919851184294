.profile-page {
  .profile-page__content {
    .profile-info {
      .form-item_birthday {
        width: 100%;
        .form-item_month_year {
          .byear,
          .bmonth,
          .bday {
            width: 30%;
            float: left;
            margin: 0;
            clear: none;
            padding-right: 1em;
          }
          .select2-container {
            .select2-choice {
              height: 56px;
              line-height: 58px;
            }
          }
        }
      }
    }
  }
}

.account-qr_section {
  display: inline-block;
  width: 40%;
}

.account-nav {
  .sidebar-shades {
    width: 0;
  }
  .grid-container {
    .profile-pic-main {
      &.hasqr_section {
        width: 35%;
        margin-right: -25%;
      }
    }
  }
}

.password-request-page {
  .password-request {
    .request-form__item {
      float: left;
      margin-right: 10px;
      input[type='submit'] {
        height: 58px;
        width: 160px;
      }
    }
  }
}

.address-popup {
  .address-form {
    &__item {
      .default_shipping_label {
        label {
          display: inline-block;
        }
      }
    }
  }
}

#ikalar-container {
  margin-top: 15px;
}
